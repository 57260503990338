var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HeadTitle'),_c('panel',{staticClass:"panel panel-danger",attrs:{"title":"Form Input NPWPD"}},[_c('div',{staticClass:"pl-4 py-2 border rounded shadow-sm"},[_c('b-button',{staticClass:"px-4",attrs:{"variant":"primary","pill":"","to":"/admin/npwpd/"}},[_c('i',{staticClass:"fa fa-arrow-circle-left pr-2"}),_vm._v(" Kembali ")])],1),_c('div',{staticClass:"py-3"},[_c('b-card',{staticClass:"shadow-sm",attrs:{"header":"EDIT DATA NPWPD","header-bg-variant":"dark","header-text-variant":"white"}},[_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.update($event)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[(_vm.message)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v("Data yang anda input tidak valid.")]):_vm._e(),_c('h3',[_vm._v("DATA NPWPD")]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("No. Induk "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_induk),expression:"form.no_induk"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has(
                                        'no_induk'
                                    ),
                                },attrs:{"type":"text","placeholder":"Masukkan Nomor Induk"},domProps:{"value":(_vm.form.no_induk)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "no_induk", $event.target.value)}}}),(_vm.form.errors.has('no_induk'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.no_induk[0]))]):_vm._e()])]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Jenis "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-4"},[_c('v-select',{staticClass:"bg-white",attrs:{"options":[
                                    'Perorangan',
                                    'Badan Usaha' ],"placeholder":"Pilih Jenis"},model:{value:(_vm.form.jenis),callback:function ($$v) {_vm.$set(_vm.form, "jenis", $$v)},expression:"form.jenis"}}),(_vm.form.errors.has('jenis'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.jenis[0]))]):_vm._e()],1)]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Bentuk Badan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-4"},[_c('v-select',{attrs:{"label":"nama","reduce":function (nama) { return nama.id; },"options":_vm.bentukBadanOptions,"placeholder":"Pilih Bentuk Badan"},model:{value:(
                                    _vm.form.bentuk_badan_id
                                ),callback:function ($$v) {_vm.$set(_vm.form, "bentuk_badan_id", $$v)},expression:"\n                                    form.bentuk_badan_id\n                                "}}),(_vm.form.errors.has('aktif'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.aktif[0]))]):_vm._e()],1)]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Nama "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama),expression:"form.nama"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has(
                                        'nama'
                                    ),
                                },attrs:{"type":"text","placeholder":"Masukkan Nama"},domProps:{"value":(_vm.form.nama)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "nama", $event.target.value)}}}),(_vm.form.errors.has('nama'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.nama[0]))]):_vm._e()])]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has(
                                        'email'
                                    ),
                                },attrs:{"type":"text","placeholder":"Masukkan Email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.form.errors.has('email'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.email[0]))]):_vm._e()])]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("No. Handphone "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nomp),expression:"form.nomp"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has(
                                        'nomp'
                                    ),
                                },attrs:{"type":"text","placeholder":"Masukkan Nomor Handphone"},domProps:{"value":(_vm.form.nomp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "nomp", $event.target.value)}}}),(_vm.form.errors.has('nomp'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.nomp[0]))]):_vm._e()])]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("No. Telepon "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.notelepon),expression:"form.notelepon"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has(
                                        'notelepon'
                                    ),
                                },attrs:{"type":"text","placeholder":"Masukkan Nomor Telepon"},domProps:{"value":(_vm.form.notelepon)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "notelepon", $event.target.value)}}}),(_vm.form.errors.has('notelepon'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.notelepon[0]))]):_vm._e()])]),_c('div',{staticClass:"py-2"},[_c('h3',[_vm._v("ALAMAT")])]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Alamat")]),_c('div',{staticClass:"col-sm-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.alamat),expression:"form.alamat"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Alamat"},domProps:{"value":(_vm.form.alamat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "alamat", $event.target.value)}}})])]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Provinsi")]),_c('div',{staticClass:"col-sm-4"},[_c('v-select',{attrs:{"label":"nama","options":_vm.provinsiOptions,"reduce":function (nama) { return nama.id; },"placeholder":"Pilih Provinsi"},on:{"input":function($event){return _vm.onProvinsi(
                                        _vm.input.provinsi
                                    )}},model:{value:(_vm.input.provinsi),callback:function ($$v) {_vm.$set(_vm.input, "provinsi", $$v)},expression:"input.provinsi"}}),(
                                    _vm.form.errors.has('wilayah_kelurahan_id')
                                )?_c('em',{staticClass:"form-text text-danger"},[_vm._v("Isian Provinsi Wajib Diisi.")]):_vm._e()],1)]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Provinsi")]),_c('div',{staticClass:"col-sm-4"},[_c('v-select',{staticClass:"bg-white",attrs:{"label":"nama","options":_vm.kotaOptions,"reduce":function (nama) { return nama.id; },"placeholder":"Pilih Kabupaten/Kota"},on:{"input":function($event){return _vm.onKabKota(_vm.input.kota)}},model:{value:(_vm.input.kota),callback:function ($$v) {_vm.$set(_vm.input, "kota", $$v)},expression:"input.kota"}}),(
                                    _vm.form.errors.has('wilayah_kelurahan_id')
                                )?_c('em',{staticClass:"form-text text-danger"},[_vm._v("Isian Kab./Kota Wajib Diisi.")]):_vm._e()],1)]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Kecamatan")]),_c('div',{staticClass:"col-sm-4"},[_c('v-select',{attrs:{"label":"nama","options":_vm.kecamatanOptions,"reduce":function (nama) { return nama.id; },"placeholder":"Pilih Kecamatan"},on:{"input":function($event){return _vm.onKecamatan(
                                        _vm.input.kecamatan
                                    )}},model:{value:(_vm.input.kecamatan),callback:function ($$v) {_vm.$set(_vm.input, "kecamatan", $$v)},expression:"input.kecamatan"}}),(
                                    _vm.form.errors.has('wilayah_kelurahan_id')
                                )?_c('em',{staticClass:"form-text text-danger"},[_vm._v("Isian Kecamatan Wajib Diisi.")]):_vm._e()],1)]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Kelurahan")]),_c('div',{staticClass:"col-sm-4"},[_c('v-select',{staticClass:"bg-white",attrs:{"label":"nama","options":_vm.kelurahanOptions,"reduce":function (nama) { return nama.id; },"placeholder":"Pilih Kelurahan"},model:{value:(_vm.form.wilayah_kelurahan_id),callback:function ($$v) {_vm.$set(_vm.form, "wilayah_kelurahan_id", $$v)},expression:"form.wilayah_kelurahan_id"}}),(
                                    _vm.form.errors.has('wilayah_kelurahan_id')
                                )?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.wilayah_kelurahan_id[0]))]):_vm._e()],1)]),_c('div',{staticClass:"row my-2"},[_c('label',{staticClass:"col-md-2 col-form-label font-weight-bold"},[_vm._v("Kodepos ")]),_c('div',{staticClass:"col-sm-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kodepos),expression:"form.kodepos"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has(
                                        'kodepos'
                                    ),
                                },attrs:{"type":"text","placeholder":"Masukkan Kodepos"},domProps:{"value":(_vm.form.kodepos)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "kodepos", $event.target.value)}}}),(_vm.form.errors.has('kodepos'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.kodepos[0]))]):_vm._e()])]),_c('hr'),_c('div',{staticClass:"float-right"},[_c('span',[_c('b-button',{staticClass:"px-4",attrs:{"variant":"primary","pill":"","type":"submit","squared":""}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Simpan")])],1)])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }