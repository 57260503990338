<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
        <panel title="Form Input NPWPD" class="panel panel-danger">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> FORM INPUT BENTUK BADAN</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button
                    variant="primary"
                    pill
                    class="px-4"
                    to="/admin/npwpd/"
                >
                    <i class="fa fa-arrow-circle-left pr-2"></i>
                    Kembali
                </b-button>
            </div>
            <div class="py-3">
                <b-card
                    class="shadow-sm"
                    header="EDIT DATA NPWPD"
                    header-bg-variant="dark"
                    header-text-variant="white"
                >
                    <b-form
                        @submit.prevent="update"
                        @keydown="form.onKeydown($event)"
                        autocomplete="off"
                    >
                        <!-- <input type="hidden" name="_token" :value="csrf"> -->
                        <b-alert v-if="message" variant="danger" show
                            >Data yang anda input tidak valid.</b-alert
                        >
                        <h3>DATA NPWPD</h3>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >No. Induk <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    v-model="form.no_induk"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'no_induk'
                                        ),
                                    }"
                                    placeholder="Masukkan Nomor Induk"
                                />
                                <em
                                    v-if="form.errors.has('no_induk')"
                                    class="form-text text-danger"
                                    >{{ errors.no_induk[0] }}</em
                                >
                            </div>
                        </div>
                         <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Jenis <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-4">
                                <v-select
                                    :options="[
                                        'Perorangan',
                                        'Badan Usaha',
                                    ]"
                                    v-model="form.jenis"
                                    placeholder="Pilih Jenis"
                                    class="bg-white"
                                ></v-select>
                                <em
                                    v-if="form.errors.has('jenis')"
                                    class="form-text text-danger"
                                    >{{ errors.jenis[0] }}</em
                                >
                            </div>
                        </div>
                         <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Bentuk Badan <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-4">
                                <v-select
                                    label="nama"
                                    :reduce="(nama) => nama.id"
                                    :options="
                                        bentukBadanOptions
                                    "
                                    v-model="
                                        form.bentuk_badan_id
                                    "
                                    placeholder="Pilih Bentuk Badan"
                                ></v-select>
                                <em
                                    v-if="form.errors.has('aktif')"
                                    class="form-text text-danger"
                                    >{{ errors.aktif[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Nama <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'nama'
                                        ),
                                    }"
                                    v-model="form.nama"
                                    placeholder="Masukkan Nama"
                                />
                                <em
                                    v-if="form.errors.has('nama')"
                                    class="form-text text-danger"
                                    >{{ errors.nama[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'email'
                                        ),
                                    }"
                                    v-model="form.email"
                                    placeholder="Masukkan Email"
                                />
                                <em
                                    v-if="form.errors.has('email')"
                                    class="form-text text-danger"
                                    >{{ errors.email[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >No. Handphone <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'nomp'
                                        ),
                                    }"
                                    v-model="form.nomp"
                                    placeholder="Masukkan Nomor Handphone"
                                />
                                <em
                                    v-if="form.errors.has('nomp')"
                                    class="form-text text-danger"
                                    >{{ errors.nomp[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >No. Telepon <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'notelepon'
                                        ),
                                    }"
                                    v-model="form.notelepon"
                                    placeholder="Masukkan Nomor Telepon"
                                />
                                <em
                                    v-if="form.errors.has('notelepon')"
                                    class="form-text text-danger"
                                    >{{ errors.notelepon[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="py-2">
                            <h3>ALAMAT</h3>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Alamat</label
                            >
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form.alamat"
                                    placeholder="Masukkan Alamat"
                                />
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Provinsi</label
                            >
                            <div class="col-sm-4">
                                <v-select
                                    label="nama"
                                    :options="provinsiOptions"
                                    :reduce="(nama) => nama.id"
                                    v-model="input.provinsi"
                                    @input="
                                        onProvinsi(
                                            input.provinsi
                                        )
                                    "
                                    placeholder="Pilih Provinsi"
                                ></v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >Isian Provinsi Wajib Diisi.</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Provinsi</label
                            >
                            <div class="col-sm-4">
                                <v-select
                                    label="nama"
                                    :options="kotaOptions"
                                    :reduce="(nama) => nama.id"
                                    v-model="input.kota"
                                    @input="
                                        onKabKota(input.kota)
                                    "
                                    placeholder="Pilih Kabupaten/Kota"
                                    class="bg-white"
                                ></v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >Isian Kab./Kota Wajib Diisi.</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Kecamatan</label
                            >
                            <div class="col-sm-4">
                                <v-select
                                    label="nama"
                                    :options="kecamatanOptions"
                                    :reduce="(nama) => nama.id"
                                    v-model="input.kecamatan"
                                    @input="
                                        onKecamatan(
                                            input.kecamatan
                                        )
                                    "
                                    placeholder="Pilih Kecamatan"
                                ></v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >Isian Kecamatan Wajib Diisi.</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Kelurahan</label
                            >
                            <div class="col-sm-4">
                                <v-select
                                    label="nama"
                                    :options="kelurahanOptions"
                                    :reduce="(nama) => nama.id"
                                    v-model="form.wilayah_kelurahan_id"
                                    placeholder="Pilih Kelurahan"
                                    class="bg-white"
                                ></v-select>
                                <em
                                    v-if="
                                        form.errors.has('wilayah_kelurahan_id')
                                    "
                                    class="form-text text-danger"
                                    >{{ errors.wilayah_kelurahan_id[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="row my-2">
                            <label
                                class="col-md-2 col-form-label font-weight-bold"
                                >Kodepos </label
                            >
                            <div class="col-sm-4">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'kodepos'
                                        ),
                                    }"
                                    v-model="form.kodepos"
                                    placeholder="Masukkan Kodepos"
                                />
                                <em
                                    v-if="form.errors.has('kodepos')"
                                    class="form-text text-danger"
                                    >{{ errors.kodepos[0] }}</em
                                >
                            </div>
                        </div>
                        <hr />
                        <div class="float-right">
                            <span>
                                <b-button
                                    variant="primary"
                                    pill
                                    type="submit"
                                    squared
                                    class="px-4"
                                    ><i class="fa fa-save"></i> Simpan</b-button
                                >
                            </span>
                        </div>
                    </b-form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from "@/assets/images/logoSumut.png";
import HeadTitle from "@/components/header/HRetribusi.vue";

import axios from "axios";

export default {
    components: {
        HeadTitle,
    },
    data() {
        return {
            imageLogo: Logo,
            user: JSON.parse(localStorage.getItem("user")),
            form: new Form({
                no_induk: "",
                bentuk_badan_id: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                published_at: "",
                pubblished_by: "",
                jenis: "",
                no_registrasi: "",
                tgl_registrasi: "",
                status_registrasi: "",
            }),

            /* // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,

            // vue-select options
            bentukBadanOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],

            // Variable wilayah
            input: {
                kota: null,
                kecamatan: null,
                provinsi: null,
            },
            // csrf token
            // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            // datepicker config
            options: {
                format: "YYYY-MM-DD",
                useCurrent: false,
            },
        };
    },
    mounted() {
        this.getDataNpwpd();
        this.getProvinsi();
        this.getBentukBadan();
    },
    methods: {
        // update data
        update() {
            this.form
                .put("/api/wajib-pajak/npwpd/" + this.$route.params.id)
                .then((response) => {
                    if (this.form.successful) {
                        this.$swal({
                            icon: "success",
                            title: "Data berhasil diupdate",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: false,
                            showCloseButton: true,
                            timer: 1500,
                        });
                        this.$router.push({ name: "NpwpProv" });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                    console.log(error);
                });
        },

        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
                this.kelurahanOptions = [];
                this.form.wilayah_kelurahan_id = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
                this.kelurahanOptions = [];
                this.form.wilayah_kelurahan_id = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
                this.kelurahanOptions = [];
                this.form.wilayah_kelurahan_id = null;
            } else {
                this.getKelurahan(id);
            }
        },

        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    // console.log(this.provinsiOptions);
                    this.getKabupaten(this.input.provinsi);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios.get("/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" + id).then((response) => {
                // console.log(response.data.data);
                this.kotaOptions = response.data.data;
                this.getKecamatan(this.input.kota);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({name: 'Login'})
                }
            });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get("/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" + id)
                .then((response) => {
                    // console.log(response.data.data);
                    this.kecamatanOptions = response.data.data;
                    this.getKelurahan(this.input.kecamatan);
                }).catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get("/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" + id)
                .then((response) => {
                    // console.log(response.data.data);
                    this.kelurahanOptions = response.data.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },

        // bentuk badan options
        getBentukBadan() {
            axios
                .get("/api/wajib-pajak/bentuk-badan/getoption")
                .then((response) => {
                    this.bentukBadanOptions = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },

        // get detail npwpd
        getDataNpwpd() {
            axios
                .get("/api/wajib-pajak/npwpd/" + this.$route.params.id)
                .then((response) => {
                    const items = response.data.data;
                    // console.log(items);
                    this.form.fill(items);
                    if (this.form.jenis < 41) {
                        this.form.jenis = "Badan Usaha";
                    } else {
                        this.form.jenis = "Perorangan";
                    }
                    this.input.provinsi = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                    // console.log(this.input.provinsi)
                    this.input.kota = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                    this.input.kecamatan = items.wilayah_kelurahan.wilayah_kecamatan_id
                    this.form.wilayah_kelurahan_id = parseInt(items.wilayah_kelurahan_id)
                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },

        // reset
        reset() {
            this.form.reset();
            this.input.provinsi = null;
            this.input.kota = null;
            this.input.kecamatan = null;
            this.input.kelurahan = null;
        },
    },
};
</script>
